import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';


// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/category/list" replace />, index: true },
        // { path: '/dashboard', element: <Navigate to="/dashboard/one" replace />, index: true },
        // { path: '/dashboard/one', element: <PageOne /> },
        // { path: '/dashboard/two', element: <PageTwo /> },
        // { path: '/dashboard/three', element: <PageThree /> },
        // { path: '/dashboard/category', element: <Category /> },
        // { path: '/dashboard/category/add-category', element: <AddCategory /> },
        // { path: '/dashboard/edit-category/:id', element: <EditCategory /> },
        {
          path: '/dashboard/category',
          children: [
            { element: <Navigate to="/dashboard/category" replace />, index: true },
            { path: '/dashboard/category/list', element: <Category /> },
            { path: '/dashboard/category/add', element: <AddCategory /> },
            { path: '/dashboard/category/edit/:id', element: <EditCategory /> },
          ],
        },
        {
          path: '/dashboard/subcategory',
          children: [
            { element: <Navigate to="/dashboard/subcategory" replace />, index: true },
            { path: '/dashboard/subcategory/list', element: <SubCategory /> },
            { path: '/dashboard/subcategory/add', element: <AddSubCategory /> },
            { path: '/dashboard/subcategory/edit/:id', element: <EditSubCategory /> },
          ],
        },
        {
          path: '/dashboard/photos',
          children: [
            { element: <Navigate to="/dashboard/photos" replace />, index: true },
            { path: '/dashboard/photos/list', element: <Photos /> },
            { path: '/dashboard/photos/add', element: <AddPhotos /> },
            // { path: '/dashboard/photos/edit-photos/:id', element: <EditPhotos /> },
          ],
        },
        {
          path: '/dashboard/slider-images',
          children: [
            { element: <Navigate to="/dashboard/slider-images" replace />, index: true },
            { path: '/dashboard/slider-images/list', element: <SliderImages /> },
            { path: '/dashboard/slider-images/add', element: <AddSliderImages /> },
            // { path: '/dashboard/slider-images/edit-photos/:id', element: <EditPhotos /> },
          ],
        },
        {
          path: '/dashboard/user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: '/dashboard/user/four', element: <PageFour /> },
            { path: '/dashboard/user/five', element: <PageFive /> },
            { path: '/dashboard/user/six', element: <PageSix /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const Category =  Loadable(lazy(() => import('../pages/category/Category')));
const AddCategory =  Loadable(lazy(() => import('../pages/category/AddCategory')));
const EditCategory =  Loadable(lazy(() => import('../pages/category/EditCategory')));

const SubCategory =  Loadable(lazy(() => import('../pages/subcategory/SubCategory')));
const AddSubCategory =  Loadable(lazy(() => import('../pages/subcategory/AddSubCategory')));
const EditSubCategory =  Loadable(lazy(() => import('../pages/subcategory/EditSubCategory')));

const Photos =  Loadable(lazy(() => import('../pages/photos/Photos')));
const AddPhotos =  Loadable(lazy(() => import('../pages/photos/AddPhotos')));
// const EditPhotos =  Loadable(lazy(() => import('../pages/photos/EditPhotos')));

const SliderImages =  Loadable(lazy(() => import('../pages/sliderImages/SliderImages')));
const AddSliderImages =  Loadable(lazy(() => import('../pages/sliderImages/AddSliderImages')));
// const EditSliderImages =  Loadable(lazy(() => import('../pages/sliderImages/EditSliderImages')));