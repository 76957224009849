// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import CategoryIcon from '@mui/icons-material/Category';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  // category: CategoryIcon
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general v3.0.0',
  //   items: [
  //     { title: 'One', path: '/dashboard/one', icon: ICONS.dashboard },
  //     { title: 'Two', path: '/dashboard/two', icon: ICONS.ecommerce },
  //     { title: 'Three', path: '/dashboard/three', icon: ICONS.analytics },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },

  {
    subheader: 'Management',
    items: [
      {
        title: 'Category',
        path: '/dashboard/category',
        icon: ICONS.analytics,
        children: [
          { title: 'Category', path: '/dashboard/category/list' },
          { title: 'Add Category', path: '/dashboard/category/add' },
          // { title: 'Six', path: '/dashboard/category/six' },
        ],
      },
      {
        title: 'SubCategory',
        path: '/dashboard/subcategory',
        icon: ICONS.analytics,
        children: [
          { title: 'SubCategory', path: '/dashboard/subcategory/list' },
          { title: 'Add SubCategory', path: '/dashboard/subcategory/add' },
          // { title: 'Six', path: '/dashboard/category/six' },
        ],
      },
      {
        title: 'Photos',
        path: '/dashboard/photos',
        icon: ICONS.analytics,
        children: [
          { title: 'Photos', path: '/dashboard/photos/list' },
          { title: 'Add Photos', path: '/dashboard/photos/add' },
          // { title: 'Six', path: '/dashboard/category/six' },
        ],
      },
      {
        title: 'Slider Images',
        path: '/dashboard/slider-images',
        icon: ICONS.analytics,
        children: [
          { title: 'Slider Images', path: '/dashboard/slider-images/list' },
          { title: 'Add Slider Images', path: '/dashboard/slider-images/add' },
          // { title: 'Six', path: '/dashboard/category/six' },
        ],
      },
    ],

  },


];

export default navConfig;
