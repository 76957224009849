// eslint-disable-next-line import/no-duplicates
import axios from "axios";

// eslint-disable-next-line import/extensions
import ConfigUrl from "../configs/urls";

const instance = axios.create({
   baseURL: ConfigUrl.baseUrl,
   headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
   }
});

// interface loginInterface {
//    email: String;
//    password: String;
// }

const login = async ({ email, password }: any) => {
   try {
      const response = await instance.post('/login', { email, password });
      return response.data;
   } catch (error) {
      throw new Error(error.message)
   }
}


const AuthService = {
   login
}

export default AuthService;