import React, { Suspense } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------
import { Provider } from 'jotai/react';
import { useHydrateAtoms } from 'jotai/react/utils';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';

import { atomsWithQuery, queryClientAtom } from 'jotai-tanstack-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DevTools } from 'jotai-devtools';
import { ErrorBoundary } from 'react-error-boundary'
import Login from './pages/auth/Login';

import toast, { Toaster } from 'react-hot-toast';


const queryClient = new QueryClient();

const HydrateAtoms = ({ children }: any) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return children;
};

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export default function App() {

  const getToken = localStorage.getItem('token')
  console.log("getToken", getToken ? true : false);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {/* <Suspense fallback={<ProgressBarStyle />}> */}
        <QueryClientProvider client={queryClient}>
          <Provider>
            {/*
 This Provider initialisation step is needed so that we reference the same
 queryClient in both atomWithQuery and other parts of the app. Without this,
 our useQueryClient() hook will return a different QueryClient object
  */}
            <HydrateAtoms>
              <ThemeProvider>
                <ThemeColorPresets>
                  <RtlLayout>
                    <MotionLazyContainer>
                      <ProgressBarStyle />
                      <Settings />
                      <ScrollToTop />
                      {
                        getToken ? <Router /> : <Login />
                      }
                      {/* <Router /> */}
                      <Toaster />
                    </MotionLazyContainer>
                  </RtlLayout>
                </ThemeColorPresets>
              </ThemeProvider>
            </HydrateAtoms>
            <DevTools />
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' panelPosition="right" />
        </QueryClientProvider>
      {/* </Suspense> */}
    </ErrorBoundary>

  );
}
